import {UserClient} from "./UserClient";
import {SdkConfig} from "./SdkConfig";
import Auth from "../Auth";
import {BusinessClient} from "./BusinessClient";
import {MembershipsClient} from "./MembershipsClient";
import {PlatformSettingsClient} from "./PlatformSettingsClient";
import {BroadcastClient} from "./BroadcastClient";

export class ProgressPodSdk {
    private readonly auth: Auth;
    userClient: UserClient;
    businessClient: BusinessClient;
    membershipsClient: MembershipsClient;
    platformSettingsClient: PlatformSettingsClient;
    broadcastClient: BroadcastClient;
    config: SdkConfig;
    context: any;

    constructor(config: SdkConfig) {
        this.config = config;
        this.auth = new Auth();
        this.userClient = new UserClient(config);
        this.businessClient = new BusinessClient(config);
        this.membershipsClient = new MembershipsClient(config);
        this.platformSettingsClient = new PlatformSettingsClient(config);
        this.broadcastClient = new BroadcastClient(config);
    }

    async isAuthenticated() {
        let authenticated = this.auth.isAuthenticated();
        if (!this.context) {
            await this.initialise();
        }
        return authenticated;
    }

    async login(username: string, password: string) {
        let response = await this.userClient.login(username, password);
        if (this.auth.isAuthenticated()) {
            await this.initialise();
        }
        return response;
    }

    private async initialise() {
        let user = await this.userClient.myUser();
        this.context = {status: 200, user}
    }
}

let config = process.env.REACT_APP_ENVIRONMENT === 'PROD' ? SdkConfig.prod() : SdkConfig.local();
export const Sdk = new ProgressPodSdk(config);