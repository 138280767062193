import React, {useEffect} from 'react';
import global from './Global.style';
import {Sdk} from "../../domain/sdk/Sdk";
import {PlatformSetting} from "../../domain/sdk/domain/PlatformSetting";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import style from "./Businesses.style";
import ButtonPrimary from "../components/ButtonPrimary";
import useForm from "../../domain/utils/useForm";

export default function PlatformSettings() {
    const {values, setValues, handleChange, handleSubmit} = useForm(onFormSubmit);

    useEffect(() => {
        (async () => {
            let settings = await Sdk.platformSettingsClient.settings();
            let values = {} as any;
            settings.forEach(setting => {
                values[setting.name] = setting.value as string;
            });
            setValues(values);
        })()
    }, [setValues]);

    async function onFormSubmit() {
        let settings = [] as PlatformSetting[];
        let obj: any = values || {};
        Object.keys(obj).forEach(key => {
            settings.push({
                name: key,
                value: obj[key]
            })
        });
        await Sdk.platformSettingsClient.updateSettings(settings);
    }

    return <>
        <form onSubmit={handleSubmit}>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.TitleWrapper}>
                        Platform Settings
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    {Object.entries(values).sort((a, b) => {
                        if (a[0].includes('Link') && !b[0].includes('Link')) {
                            return -1;
                        }
                        return a[0].localeCompare(b[0]);
                    }).map(([name, value]) => {
                            return <div key={name}>
                                <FormInputTextWithLabel
                                    label={name}
                                    name={name}
                                    value={String(value) ?? ''}
                                    onChange={handleChange}
                                />
                            </div>
                        })}
                </div>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <ButtonPrimary style={style.ActionButton} text="Save" type="submit"/>
                </div>
            </div>
        </form>
    </>;
}
