import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./ui/Home";

const NotFound = () => <span>Sorry, page not found</span>;

function App() {
    return (
        <Routes>
            <Route path="/*" element={<Home/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
