import {SdkConfig} from "./SdkConfig";
import {User} from "./domain/User";
import {httpGet, httpPost} from "./HttpClient";

export class UserClient {
    private readonly usersUrl: string;

    constructor(config: SdkConfig) {
        this.usersUrl = `${config.apiUrl}/v1/users`;
    }

    async myUser(): Promise<User> {
        let response = await httpGet(`${this.usersUrl}/my-user`);
        return response.data as User;
    }

    async login(username: string, password: string) {
        let response = await httpPost(`${this.usersUrl}/login`, {username, password});
        if (response && response.status === 200 && response.headers.authorization) {
            let token = response.headers.authorization.replace('Bearer', '').trim();
            localStorage.setItem('access_token', token);
        }
        return response;
    }

    logout() {
        localStorage.removeItem('access_token');
    }
}