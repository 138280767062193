import React, {useEffect, useState} from 'react';
import global from './Global.style';
import style from './Businesses.style';
import {Sdk} from "../../domain/sdk/Sdk";
import {Business} from "../../domain/sdk/domain/Business";
import ButtonPrimary from "../components/ButtonPrimary";
import {PendingBusiness} from "../../domain/sdk/domain/PendingBusiness";
import {Link} from "react-router-dom";
import {User} from "../../domain/sdk/domain/User";

export default function Businesses() {
    const [pendingVerificationBusinesses, setPendingVerificationBusinesses]: any = useState([] as PendingBusiness[]);
    const [pendingBusinesses, setPendingBusinesses]: any = useState([] as PendingBusiness[]);
    const [businesses, setBusinesses]: any = useState([] as Business[]);

    useEffect(() => {
        (async () => {
            await loadBusinesses();
        })()
    }, []);

    async function loadBusinesses() {
        let businesses = await Sdk.businessClient.pendingBusinesses();
        setPendingVerificationBusinesses(businesses.filter((business: PendingBusiness) => !business.verified));
        setPendingBusinesses(businesses.filter((business: PendingBusiness) => business.verified));
        setBusinesses(await Sdk.businessClient.businesses());
    }

    async function approveAndReload(business: PendingBusiness) {
        await Sdk.businessClient.approve(business.code);
        await loadBusinesses();
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.TitleWrapper}>
                        Businesses
                    </div>
                </div>
            </div>


            {pendingBusinesses.length > 0 && <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.SubHeadingText}>
                        Pending approval
                    </div>
                </div>
            </div>}
            {pendingBusinesses.map((pendingBusiness: PendingBusiness, index: number) => {
                return <div key={index}>
                    <div className={`row`}>
                        <div className={`columns small-12 medium-6 medium-offset-2`}>
                            <div style={style.BusinessRow}>
                                {pendingBusiness.name}
                            </div>
                            <div style={style.BusinessDetailsRow}>
                                {pendingBusiness.user.firstName} {pendingBusiness.user.lastName}, {pendingBusiness.user.email}
                            </div>
                        </div>
                        {pendingBusiness.verified && <>
                            <div className={`columns medium-2 hide-for-small-only`}>
                                <div style={style.BusinessRow}>
                                    <ButtonPrimary style={style.FullWidth} text="Approve" type="button" onClick={async () => {
                                        await approveAndReload(pendingBusiness);
                                    }}/>
                                </div>
                            </div>
                            <div className={`columns small-12 show-for-small-only`}>
                                <div style={style.BusinessRow}>
                                    <ButtonPrimary style={style.FullWidth} text="Approve" type="button" onClick={async () => {
                                        await approveAndReload(pendingBusiness);
                                    }}/>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            })}

            {pendingBusinesses.length > 0 && <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <hr/>
                </div>
            </div>}


            {pendingVerificationBusinesses.length > 0 && <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.SubHeadingText}>
                        Pending owner email verification
                    </div>
                </div>
            </div>}
            {pendingVerificationBusinesses.map((pendingBusiness: PendingBusiness, index: number) => {
                return <div key={index}>
                    <div className={`row`}>
                        <div className={`columns small-12 medium-6 medium-offset-2`}>
                            <div style={style.BusinessRow}>
                                {pendingBusiness.name}
                            </div>
                            <div style={style.BusinessDetailsRow}>
                                {pendingBusiness.user.firstName} {pendingBusiness.user.lastName}, {pendingBusiness.user.email}
                            </div>
                        </div>
                    </div>
                </div>
            })}
            {pendingVerificationBusinesses.length > 0 && <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <hr/>
                </div>
            </div>}


            {businesses.length > 0 && <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.SubHeadingText}>
                        Approved businesses
                    </div>
                </div>
            </div>}
            {businesses.filter((business:any) => !business.disabled).map((business: Business, index: number) => {
                return <div key={index}>
                    <div className={`row`}>
                        <div className={`columns small-12 medium-6 medium-offset-2`}>
                            <div style={style.BusinessRow}>
                                <Link style={style.BusinessRow} to={`/businesses/${business.id}`}>{business.name}</Link>
                                <Link to={"/businesses/disable"} style={style.DisableEnableLink} state={{id: business.id, name: business.name}}>
                                    (<span style={{textDecoration: "underline"}}>Disable</span>)
                                </Link>
                            </div>
                            <div style={style.BusinessDetailsRow}>
                                {business.owner.firstName} {business.owner.lastName}, {business.owner.email}
                            </div>
                            {business.admins?.map((admin: User, index: number) => {
                                return <div key={`admin_${index}`} style={style.BusinessDetailsRow}>
                                    {admin.firstName} {admin.lastName}, {admin.email} (Admin)
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            })}

            {businesses.length > 0 && <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <hr/>
                    <div style={global.SubHeadingText}>
                        Disabled businesses
                    </div>
                </div>
            </div>}
            {businesses.filter((business:any) => business.disabled).map((business: Business, index: number) => {
                return <div key={index}>
                    <div className={`row`}>
                        <div className={`columns small-12 medium-6 medium-offset-2`}>
                            <div style={style.BusinessRow}>
                                <Link style={style.BusinessRow} to={`/businesses/${business.id}`}>{business.name}</Link>
                                <Link to={"/businesses/enable"} style={style.DisableEnableLink} state={{id: business.id, name: business.name}}>
                                    (<span style={{textDecoration: "underline"}}>Enable</span>)
                                </Link>
                            </div>
                            <div style={style.BusinessDetailsRow}>
                                {business.owner.firstName} {business.owner.lastName}, {business.owner.email}
                            </div>
                            {business.admins?.map((admin: User, index: number) => {
                                return <div key={`admin_${index}`} style={style.BusinessDetailsRow}>
                                    {admin.firstName} {admin.lastName}, {admin.email} (Admin)
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>;
}
