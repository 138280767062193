import Theme from "../Theme";

const global: any = {
    TitleWrapper: {
        paddingTop: 50,
        paddingBottom: 26,
        color: Theme.selected.accent,
        fontSize: 23,
    },

    SubHeadingText: {
        fontSize: 21,
        color: "#606060",
        marginTop: 20,
        marginBottom: 5,
        fontWeight: 300
    },

    InputWrapper: {
        marginBottom: 10
    },

    LastInputWrapper: {
        paddingBottom: 20,
    },

    ActionButton: {
        width: '100%',
        fontSize: 22,
        float: "right",
    },

    CancelButton: {
        width: "39%",
        fontSize: 22,
    },

    FullWidth: {
        width: "100%",
    },

    SmallText: {
        fontSize: 11
    },

    ErrorMessage: {
        color: "#EA7E3D",
        margin: 0,
        paddingTop: 0,
        paddingRight: 5,
        paddingBottom: 0,
        paddingLeft: 5,
    }
}

export default global;