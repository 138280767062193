import Theme from "../Theme";

const styles: any = {
    MembershipRow: {
        color: Theme.selected.text,
        fontSize: 21,
        verticalAlign: "middle",
        lineHeight: "60px"
    },
    ActionButton: {
        width: 150,
        fontSize: 21,
        float: "right",
    }
}

export default styles;