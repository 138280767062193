export class Defaults {
    black: string = '#000000';
    darkGrey: string = '#606060';
    grey: string = '#B2B2B2';
    lightGrey: string = '#DDDDDD';
}

export class Themes {
    light: Theme = {
        name : 'light',
        text : '#37694A',
        background : '#FFFFFF',
        tint : '#78906B',
        accent : '#EA7E3D',
        outline : '#F4B144',
        soft : '#E5CB81'
    };
    dark: Theme = {
        name: 'dark',
        text: '#FFFFFF',
        background: '#214A59',
        tint: '#FFFFFF',
        accent: '#FED630',
        outline: '#707070',
        soft: '#707070'
    };
    defaults = new Defaults();
    selected = this.light;
}

export interface Theme {
    name: string;
    text: string;
    background: string;
    tint: string;
    accent: string;
    outline: string;
    soft: string;
}

const themes = new Themes();
export default themes;