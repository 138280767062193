import React, {useMemo, useState} from 'react';
import global from './Global.style';
import {Sdk} from "../../domain/sdk/Sdk";
import style from "./Businesses.style";
import ButtonPrimary from "../components/ButtonPrimary";
import FormInputText from "../components/FormInputText";
import {BroadcastMessage} from "../../domain/sdk/domain/BroadcastMessage";
import {DateTime} from "luxon";
import FormTextarea from "../components/FormTextarea";

export default function MessageBroadcast() {
    const [title, setTitle] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [notification, setNotification] = useState<string>();
    const [previous, setPrevious] = useState<BroadcastMessage[]>([]);
    const [disabled, setDisabled] = useState(false);

    useMemo(() => {
        (async () => {
            setPrevious(await Sdk.broadcastClient.broadcasts());
        })();
    }, []);

    async function broadcastMessage() {
        if (title && message) {
            setTitle('');
            setMessage('');
            setDisabled(true);
            setNotification('Sending message');
            await Sdk.broadcastClient.broadcast(title, message);
            setNotification('Request sent');
            setDisabled(false);
        }
    }

    return <>
        <div className={`row`}>
            <div className={`columns small-12 medium-8 large-8 small-centered`}>
                <div style={global.TitleWrapper}>
                    Broadcast in-app notification
                </div>
            </div>
        </div>
        <div className={`row`}>
            <div className={`columns small-12 medium-8 large-8 small-centered`}>
                <FormInputText
                    style={{marginBottom: 20}}
                    placeholder="Title"
                    name="title"
                    onChange={(text) => setTitle(text.target.value)}
                    value={title ?? ''}
                />
                <FormTextarea
                    style={{marginBottom: 20}}
                    placeholder="Message"
                    rows={10}
                    name="message"
                    onChange={(text) => setMessage(text.target.value)}
                    value={message ?? ''}
                />
                <div style={{marginBottom: 10}}>
                    This is an async operation and may take a minute to complete. Refresh to see the message below.
                </div>
                <div style={{display: "flex"}}>
                    <div style={{marginLeft: "auto"}}>
                        <ButtonPrimary style={{...style.ActionButton}} text="Send" type="button" disabled={disabled} onClick={() => broadcastMessage()}/>
                    </div>
                </div>
                <br/>
                {notification}
            </div>
        </div>

        <div className={`row`}>
            <div className={`columns small-12 medium-8 large-8 small-centered`}>
                <div style={global.SubHeadingText}>Previous notifications</div>
            </div>
        </div>

        <div className={`row`}>
            <div className={`columns small-12 medium-8 large-8 small-centered`}>
                {previous.map((message) => {
                    const dateTime = DateTime.fromMillis(message.epoch).toLocal();
                    return <div key={message.epoch} style={{marginBottom: 20}}>
                        <div style={{fontWeight: 'bold', marginBottom: 5}}>{message.title}</div>
                        <div style={{marginBottom: 5, fontWeight: '200', fontSize: 15}}>
                            Sent to {message.quantity} users on {dateTime.toFormat(`dd LLL yyyy`)} at {dateTime.toFormat(`hh:mm`)}
                        </div>
                        <div style={{marginBottom: 10}}>{message.message}</div>
                        <div style={{borderBottom: "1px solid gray"}}></div>
                    </div>;
                })}
            </div>
        </div>
    </>;
}
