import styles from './HeaderLoggedIn.module.css';
import LogoWrapper from './/LogoWrapper';
import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Sdk} from "../../domain/sdk/Sdk";
import MenuIcon from "../svg/Menu";

export interface HeaderLoggedInProps {
    className?: string;
}

export default function HeaderLoggedIn({className}: HeaderLoggedInProps) {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    let menuRef:any = useRef(null);

    useEffect(() => {
        function handleClickOutside(event:any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return <>
        <div className={`${styles.Header} ${className ? className : ''}`}>
            <div className={`row`}>
                <div className={`columns small-4`}>
                    <div className={`${styles.LogoWrapper}`}>
                        <LogoWrapper onClick={() => navigate('/businesses')}/>
                    </div>
                </div>
                <div className={`columns small-8`}>
                    <div className={`${styles.MenuWrapper}`}>
                        <MenuIcon className={`${styles.MenuIcon}`} onClick={() => setShowMenu(!showMenu)}/>
                        {showMenu && <div ref={menuRef} className={`${styles.Menu}`} onMouseLeave={e => setShowMenu(false)}>
                            <div className={`${styles.MenuItem}`}>
                                <Link className={`${styles.MenuLink}`} to={"/businesses"} onClick={() => setShowMenu(false)}>Businesses</Link>
                            </div>
                            <div className={`${styles.MenuItem}`}>
                                <Link className={`${styles.MenuLink}`} to={"/platform/broadcast"} onClick={() => setShowMenu(false)}>Send broadcast</Link>
                            </div>
                            <div className={`${styles.MenuItem}`}>
                                <Link className={`${styles.MenuLink}`} to={"/platform/settings"} onClick={() => setShowMenu(false)}>Platform settings</Link>
                            </div>
                            <div className={`${styles.MenuItem}`}>
                                <Link className={`${styles.MenuLink}`} to={"/"} onClick={() => Sdk.userClient.logout()}>Logout</Link>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </>;
}
