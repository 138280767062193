import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost} from "./HttpClient";
import {Business} from "./domain/Business";
import {PendingBusiness} from "./domain/PendingBusiness";

export class BusinessClient {
    private readonly businessUrl: string;

    constructor(config: SdkConfig) {
        this.businessUrl = `${config.apiUrl}/v1/businesses`;
    }

    async businesses():Promise<Business[]> {
        let response = await httpGet(`${this.businessUrl}/businesses`);
        let data = response.data;
        if (response.status === 200) {
            return data.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    async pendingBusinesses():Promise<PendingBusiness[]> {
        let response = await httpGet(`${this.businessUrl}/pending-businesses`);
        let data = response.data;
        if (response.status === 200) {
            return data.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    async approve(businessId: string) {
        return await httpPost(`${this.businessUrl}/approve?code=${businessId}`);
    }
    async disable(businessId: string) {
        return await httpPost(`${this.businessUrl}/${businessId}/disable`);
    }
    async enable(businessId: string) {
        return await httpPost(`${this.businessUrl}/${businessId}/enable`);
    }
}