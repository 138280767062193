import React from 'react';
import {Route, Routes} from "react-router-dom";
import HeaderLoggedOut from "./components/HeaderLoggedOut";
import SignIn from "./screens/SignIn";
import Secure from "./Secure";
import Businesses from "./screens/Businesses";
import Memberships from "./screens/Memberships";
import PlatformSettings from "./screens/PlatformSettings";
import Membership from "./screens/Membership";
import DisableBusiness from "./screens/DisableBusiness";
import EnableBusiness from "./screens/EnableBusiness";
import MessageBroadcast from "./screens/MessageBroadcast";

export default function Home() {
    return <Routes>
        <Route path={"/"} element={<HeaderLoggedOut/>}>
            <Route index element={<SignIn/>}/>
        </Route>
        <Route path={"/"} element={<Secure/>}>
            <Route path={"/businesses"} element={<Businesses/>}/>
            <Route path={"/businesses/enable"} element={<EnableBusiness/>}/>
            <Route path={"/businesses/disable"} element={<DisableBusiness/>}/>
            <Route path={"/businesses/:businessId"} element={<Memberships/>}/>
            <Route path={"/platform/settings"} element={<PlatformSettings/>}/>
            <Route path={"/platform/broadcast"} element={<MessageBroadcast/>}/>
            <Route path={"/memberships/:membershipId"} element={<Membership/>}/>
            <Route path={"/businesses/:businessId"} element={<Memberships/>}/>
        </Route>
    </Routes>
};
