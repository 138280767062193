import {SdkConfig} from "./SdkConfig";
import {Membership} from "./domain/Membership";
import {httpGet} from "./HttpClient";
import {Member} from "./domain/Member";

export class MembershipsClient {
    private readonly businessesUrl: string;
    private readonly membershipsUrl: string;

    constructor(config: SdkConfig) {
        this.businessesUrl = `${config.apiUrl}/v1/businesses`;
        this.membershipsUrl = `${config.apiUrl}/v1/memberships`;
    }

    async memberships(businessId: string): Promise<Membership[]> {
        let response = await httpGet(`${this.businessesUrl}/${businessId}/memberships`);
        if (response.status === 200) {
            return response.data.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    async members(membershipId: string): Promise<Member[]> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/members`);
        if (response.status === 200) {
            return response.data.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    async lastActive(membershipId:string): Promise<Map<String, Number>> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/last-active`);
        return response.data;
    }
}