import {jwtDecode} from 'jwt-decode';
import {Sdk} from "./sdk/Sdk";

export default class Auth {

    public isAuthenticated() {
        let token = localStorage.getItem('access_token');
        if (token) {
            let decodedToken:any = jwtDecode(token as string);
            if (decodedToken.exp) {
                return new Date().getTime() < decodedToken.exp * 1000;
            }
        }
        return false;
    }

    public static logout = async () => {
        localStorage.removeItem('access_token');
        Sdk.context = undefined;
    };
}