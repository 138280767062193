import React, {useEffect, useState} from 'react';
import global from './Global.style';
import {useParams} from "react-router-dom";
import {Sdk} from "../../domain/sdk/Sdk";
import {Member} from "../../domain/sdk/domain/Member";
import {DateTime} from "luxon";

export default function Membership() {
    const {membershipId} = useParams();
    const [members, setMembers]: any = useState([] as Member[]);
    const [activeUsers, setActiveUsers]: any = useState(new Map<String, Number>());
    const [loaded, setLoaded]: any = useState(false);

    useEffect(() => {
        if (membershipId) {
            (async () => {
                setMembers(await Sdk.membershipsClient.members(membershipId));
                setActiveUsers(await Sdk.membershipsClient.lastActive(membershipId));
                setLoaded(true);
            })()
        } else {
            setLoaded(true);
        }
    }, [membershipId]);

    function showLastActive(member: Member) {
        if (activeUsers[member.id]) {
            let diff = DateTime.now().diff(DateTime.fromSeconds(activeUsers[member.id]), ["years", "months", "days", "hours", "minutes", "seconds"]);
            if (diff.years > 0) {
                return `${diff.years} years ago`;
            } else if (diff.months > 0) {
                return `${diff.months} months ago`;
            } else if (diff.days > 0) {
                return `${diff.days} days ago`;
            } else if (diff.hours > 0) {
                return `${diff.hours} hours ago`;
            } else if (diff.minutes > 30) {
                return `${diff.minutes} minutes ago`;
            } else if (diff.minutes >= 0) {
                return `just now`;
            }
        }
    }

    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.TitleWrapper}>
                        Members
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    {members.map((member: Member, index:number) => {
                        let lastActive = showLastActive(member);
                        return <div key={index}>
                            {member.firstName} {member.lastName} {member.leader ? '(leader)' : ''}
                            {lastActive && <div style={global.SmallText}>active {lastActive}</div>}
                        </div>
                    })}
                </div>
            </div>
        </div>}
    </>;
}
