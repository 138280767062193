import styles from './HeaderHome.module.css';
import React from 'react';
import ButtonSecondary from "./ButtonSecondary";
import {useNavigate} from "react-router-dom";
import LogoWrapper from "./LogoWrapper";

export interface HeaderHomeProps {
    className?: string;
}

export default function HeaderHome({className}:HeaderHomeProps) {
    let navigate = useNavigate();
    return <>
        <div className={`${styles.Header} ${className ? className : ''}`}>
            <div className={`row`}>
                <div className={`columns small-6`}>
                    <div className={`${styles.LogoWrapper}`}>
                        <LogoWrapper />
                    </div>
                </div>
                <div className={`columns small-6`}>
                    <div className={`${styles.SignInWrapper}`}>
                        <ButtonSecondary text="Login" type="button" onClick={() => {
                            navigate('/sign-in');
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
