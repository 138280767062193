import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Sdk} from "../domain/sdk/Sdk";
import HeaderLoggedIn from "./components/HeaderLoggedIn";
import HeaderHome from "./components/HeaderHome";

export default function Secure() {
    const navigate = useNavigate();
    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        (async () => {
            if (location.pathname !== '/') {
                let authed = await Sdk.isAuthenticated();
                if (!authed) {
                    navigate("/");
                }
                setAuthenticated(authed);
            }
        })()
    }, [location.pathname, navigate]);

    return <>
        {location.pathname !== '/' && authenticated && <>
            <HeaderLoggedIn />
            <Outlet />
        </>}
        {location.pathname === '/' && <HeaderHome />}
    </>
}