import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPut} from "./HttpClient";
import {PlatformSetting} from "./domain/PlatformSetting";

export class PlatformSettingsClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/platform-settings`;
    }

    async settings(): Promise<PlatformSetting[]> {
        let response = await httpGet(this.url);
        if (response.status === 200) {
            return response.data.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    async updateSettings(settings: PlatformSetting[]) {
        return await httpPut(this.url, {settings});
    }
}