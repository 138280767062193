import styles from './LogoWrapper.module.css'
import React, {HTMLAttributes} from 'react';
import Logo from '../svg/Logo';

export default function LogoWrapper({className, onClick}: HTMLAttributes<any>) {
    return <div className={`${styles.Wrapper} ${className ? className : ''}`}>
        <Logo onClick={onClick}/>
        <div className={`${styles.Text}`}>ProgressPod.io</div>
    </div>
}
