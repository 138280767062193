import React from 'react';
import styles from './DisableEnableBusiness.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";

export default function EnableBusiness() {
    const {state} = useLocation();
    const navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Confirm Enable
                    </div>
                </div>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        "{state.name}" will now have access
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidth} text="Confirm" type="button" onClick={async () => {
                        await Sdk.businessClient.enable(state.id);
                        navigate('/businesses');
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/businesses');
                    }}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/businesses');
                    }}/>
                    <ButtonPrimary style={styles.ConfirmButton} text="Confirm" type="button" onClick={async () => {
                        await Sdk.businessClient.enable(state.id);
                        navigate('/businesses');
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
