import React, {useEffect, useState} from 'react';
import global from './Global.style';
import {Membership} from "../../domain/sdk/domain/Membership";
import {Link, useParams} from "react-router-dom";
import {Sdk} from "../../domain/sdk/Sdk";
import style from "./Memberships.style";

export default function Memberships() {
    const {businessId} = useParams();
    const [memberships, setMemberships]: any = useState([] as Membership[]);

    useEffect(() => {
        if (businessId) {
            (async () => {
                setMemberships(await Sdk.membershipsClient.memberships(businessId));
            })()
        }
    }, [businessId]);
    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    <div style={global.TitleWrapper}>
                        Memberships
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8 large-8 small-centered`}>
                    {memberships.map((membership: Membership, index:number) => {
                        return <div key={index}>
                            <Link style={style.MembershipRow} to={`/memberships/${membership.membershipId}`}>{membership.name}</Link>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </>;
}
