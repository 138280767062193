import Theme from "../Theme";

const styles: any = {
    BusinessRow: {
        color: Theme.selected.text,
        fontSize: 21,
        marginRight: 15
    },
    BusinessDetailsRow: {
        color: Theme.selected.tint,
        fontSize: 15,
        paddingBottom: 10
    },
    DisableEnableLink: {
        color: Theme.selected.accent,
        fontSize: 15,
        paddingBottom: 5,
    },
    FullWidth: {
        width: '100%'
    }
}

export default styles;