import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost} from "./HttpClient";
import {BroadcastMessage} from "./domain/BroadcastMessage";

export class BroadcastClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/notifications/broadcast`;
    }

    async broadcast(title: string, message: string) {
        return await httpPost(this.url, {title, message});
    }

    async broadcasts() {
        const response = await httpGet(this.url);
        if (response.status === 200) {
            return response.data as BroadcastMessage[];
        }
        return [];
    }
}