import {useEffect, useState} from 'react';

const useForm = (callback, validate) => {

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        setIsSubmitting(true);
        if (validate) {
            setErrors(validate(values));
        } else {
            callback();
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [callback, isSubmitting, errors]);

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleCheckboxChange = (event) => {
        event.persist();
        setValues(values => ({...values, [event.target.name]: event.target.checked}));
    }

    const setInitialValues = (values) => {
        setValues(values);
    };

    return {
        handleChange,
        handleCheckboxChange,
        handleSubmit,
        setInitialValues,
        values,
        setValues,
        errors,
        setErrors
    }
};

export default useForm;